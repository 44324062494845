:root {
  --text: var(--blue-1);
  --text-secondary: var(--blue-2);
  --top: var(--wheat-12);
  --background: var(--blue-11);
  --elevated: var(--blue-10);
  --sunken: var(--blue-12);
  --drop: var(--blue-12);
  --inset: var(--blue-10);
  --separator: var(--blue-10);
  --separator-on-elevated: var(--wheat-8);
  --input: var(--wheat-8);
  --input-icon: var(--wheat-2);
  --accent: var(--lime-8);
  --accent-drop: var(--grass-3);
  --accent-inset: var(--grass-2);
  --on-accent: var(--wheat-1-06);
  --link: var(--lime-5);
  --visited: var(--lime-5);
  --active: var(--blood-2);
  --focus: var(--sun-1-2);
  --focus-drop: var(--sun-1-5);
  --focus-inset: var(--sun-1-1);
  --on-focus: var(--grass-9);
  --on-focus-link: var(--sky-6);
  --on-focus-visited: var(--violet-6);
  --on-focus-active: var(--blood-6);
  --meta: var(--wheat-12);
  --on-meta: var(--wheat-1-5);
  --error: var(--blood-6);
  --on-error: var(--wheat-1);
  --toggle-label: var(--wheat-1-06);
  --toggle-track: var(--wheat-1-2);
  --toggle-thumb: var(--lime-8);
  --logo: var(--wheat-1-06);
  --logo-secondary: var(--pink-5);
  --tree-trunk: var(--grass-1-2);
  --tree-roots: var(--wheat-12);
  --icon: var(--pink-5);
  --icon-secondary: var(--pink-3);
  --ds-background: var(--wheat-17);
}
